import React from 'react'
import {
  EmailIcon,
  FeedbackIcon,
  GridIcon,
  LockIcon,
  LogOutLeaveBoardIcon,
  Menu,
  MenuItem,
  NameFieldPersonalDetailsIcon,
  CookieConsentWrapper
} from 'boards-web-ui'

import { Link, useLocation } from 'react-router-dom'
import { Trans } from 'react-i18next'
import {
  ProfileMenuSourceEnum,
  useProfileMenuEvents,
} from '@features/analytics/useProfileMenuEvents'
import { useAuth } from '@hooks/useAuth'
import useAccountSubscriptionEvents, {
  SubscriptionSourceEnum,
} from '@features/analytics/useAccountSubscriptionEvents'

import stylesAccount from '@features/account/components/AccountMenu.module.css'

import { IconWrapper } from '@ui/components/IconWrapper'
import { useProfileTranslations } from '../hooks/useProfileTranslations'
import { useUpdatesTools } from '../../updates/hooks/useUpdatesTools'
import VideoUsage from '../../../../ui/components/VideoUsage'
import { useProfileInformation } from '../hooks/useProfileInformation'
import useUserFeatures, { Features } from '../../../../hooks/useUserFeatures'

import links from '../../../constants/links'
import {
  ACCOUNT,
  USER_MESSAGE_PREFERENCES,
  USER_PERSONAL_INFO,
  USER_SUBSCRIPTION,
} from '../../../constants/routes'

import styles from './ProfileMenu.module.css'

export const ProfileMenu: React.FC = () => {
  const { logOut, feedBack, personalInfo, messagePreferences, subscription } =
    useProfileTranslations()
  const { signOut } = useAuth()

  const { close } = useUpdatesTools()
  const { profile } = useProfileInformation()
  const location = useLocation()

  const { subscriptionClickEvent } = useAccountSubscriptionEvents()
  const {
    appFeedBackClickEvent,
    appLogoutClickEvent,
    appInfoClickEvent,
    appEmailPreferencesClick,
  } = useProfileMenuEvents(ProfileMenuSourceEnum.Menu)

  const { enabled, tryFree } = useUserFeatures(Features.uploadVideo)

  const handleLogOutClick = () => {
    appLogoutClickEvent()
    signOut()
    close()
  }

  const isShowVideoUsage =
    enabled && !tryFree && Number.isInteger(profile.plan.limits.videos)

  return (
    <>
      {isShowVideoUsage ? (
        <div className={styles.VideoUsageContainer}>
          <VideoUsage
            limit={profile.plan.limits.videos}
            used={profile.usage.videos}
          />
        </div>
      ) : null}

      <Menu className={styles.Menu}>
        <MenuItem as="span">
          <Link
            to={`${ACCOUNT}/${USER_PERSONAL_INFO}`}
            state={location.pathname}
            onClick={appInfoClickEvent}
          >
            <IconWrapper className={stylesAccount.Icon}>
              <NameFieldPersonalDetailsIcon />
            </IconWrapper>
            {personalInfo()}
          </Link>
        </MenuItem>

        <MenuItem as="span">
          <Link
            to={`${ACCOUNT}/${USER_SUBSCRIPTION}`}
            state={location.pathname}
            onClick={() => subscriptionClickEvent(SubscriptionSourceEnum.Menu)}
          >
            <IconWrapper className={stylesAccount.Icon}>
              <GridIcon />
            </IconWrapper>
            {subscription()}
          </Link>
        </MenuItem>

        <MenuItem as="span">
          <Link
            to={`${ACCOUNT}/${USER_MESSAGE_PREFERENCES}`}
            state={location.pathname}
            onClick={appEmailPreferencesClick}
          >
            <IconWrapper className={stylesAccount.Icon}>
              <EmailIcon />
            </IconWrapper>
            {messagePreferences()}
          </Link>
        </MenuItem>
      </Menu>

      <div className={styles.Divider} />

      <Menu className={styles.Menu}>
        <CookieConsentWrapper>
          <MenuItem as="button" type="button">
              <IconWrapper className={stylesAccount.Icon}>
                <LockIcon />
              </IconWrapper>
              <Trans i18nKey="consent_preferences_title"></Trans>
          </MenuItem>
        </CookieConsentWrapper>

        <MenuItem
          as="a"
          href={links.supportUrl}
          target="_blank"
          rel="noopener noreferrer"
          onClick={appFeedBackClickEvent}
        >
          <IconWrapper className={stylesAccount.Icon}>
            <FeedbackIcon />
          </IconWrapper>
          {feedBack()}
        </MenuItem>

        <MenuItem as="button" type="button" onClick={handleLogOutClick}>
          <IconWrapper className={stylesAccount.Icon}>
            <LogOutLeaveBoardIcon />
          </IconWrapper>
          {logOut()}
        </MenuItem>
      </Menu>
    </>
  )
}
